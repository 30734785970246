<template>
    <div class="s-modal">       
        <h2>Themen</h2>
        <div class="themenliste">
            <thema-item v-for="thema in themen" :key="thema.id" :thema="thema" @delThema="getThemen()"></thema-item>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ThemaItem from './ThemaItem.vue'
export default {
    name: 'ModalThemen',
    components: {
        ThemaItem
    },
    data: () => ({
        themen: [],
    }),
    mounted() {
        this.getThemen()
    },
    methods: {
        // addThema() {
        //     if (this.newstadt !== '' && this.newvort !== '' && this.newadresse !== '') {
        //     // let newort = { stadt: this.newstadt, vort: this.newvort, adresse: this.newadresse, id: 4 }
        //     // this.themen.push(newort)

        //         axios.post('/login.php?editor=seminarverwaltung&rest=themen', {
        //             stadt: this.newstadt,
        //             ort: this.newvort,
        //             strasse: this.newadresse
        //         })
        //         .then(response => {
        //             console.log(response)
        //             this.newstadt = this.newvort = this.newadresse = ''
        //             this.getthemen()
        //             // this.themen = response.data
        //         })
        //     }
        // },
        getThemen() {
            axios.get('/login.php?editor=seminarverwaltung&rest=themen')
                .then(response => {
                    //  console.log(response.data)
                    this.themen = response.data
                })
        }
    }
}
</script>

<style lang="scss">

.themenliste {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    // margin: 0 -5px;
    button {
        align-self: flex-start
    }
    .row {
        &.hover:hover {
            background-color: gray;
        }
        // display: flex;
        // margin: 0 -10px;
        padding: 5px;
        // .s-col {
        //     padding: 10px;
        //     flex-basis: 30%;
        //     input {
        //         width: 100%;
        //     }
        // }
        // .s-col-auto {
        //     padding: 10px;
        //     flex-basis: 10%;
        //     text-align: right;
        // }
    }
}
</style>