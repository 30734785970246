<template>

    <div class="s-modal">
        <h2>Thema </h2>
        <div class="liste">
            <div class="list-item">

                <div class="s-col">
                    <input type="text" v-model="name" placeholder="Name" class="form-control">
                </div>
                <div class="s-col">
                    <select v-model="isSeminar" class="form-control">
                        <option value="0" >Seminar</option> 
                        <option value="1" >Arbeitgebertreffen</option> 
                    </select>
                        {{isSeminar}}
                </div>
                <div class="s-col">
                    <select v-model="fileId" class="form-control">
                        <option v-for="page in pages" id="page.id" :key="page.id" :value="page.id" v-html="page.name"></option>
                    </select>
                </div>
            </div>
            <button @click="addThema" class="btn btn-outline-primary" 
                    :disabled="this.newstadt == '' || this.newvort == '' || this.newadresse == ''">
                        HinzufÃ¼gen
            </button>
        </div>
        <!-- <div class="liste">
            <ort-item v-for="ort in orte" :key="ort.id" :ort="ort" @delOrt="getOrte()"></ort-item>
        </div> -->
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ModalThema',
    data: () => ({
        name: '',
        isSeminar: '',
        fileId: '',
        pages: []
    }),
    mounted() {
        this.getPageList()
    },
    methods: {
        getPageList() {
            axios.get('/login.php?editor=seminarverwaltung&rest=pages')
                .then(response => {
                    //  console.log(response.data)
                    this.pages = response.data
                })
        },
        addThema() {
            if (this.name !== '' && this.isSeminar !== '' && this.fileId !== '') {
                axios.post('/login.php?editor=seminarverwaltung&rest=themen', {
                    name: this.name,
                    isSeminar: this.isSeminar,
                    file_id: this.fileId
                })
                .then(response => {
                    console.log(response)
                    this.name = this.isSeminar = this.fileId = ''
                    // this.getOrte()
                    // this.orte = response.data
                })
            }
        }
    },
    props: {
        themaid: Number
    }
}
</script>

<style>

</style>
