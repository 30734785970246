<template>
    <div class="s-modal">
        <div v-if="!seminarid">Neues </div> Seminar {{seminarid}}
        <div class="container">
            <div class="row">
                <div class="col">
                    <select v-model="seminar.place"> 
                        <option v-for="ort in orte" :key="ort.id" :value="ort.id">{{ort.stadt}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Datum:
                </div>
                <div class="col">
                    <datepicker :value="datum" name="uniquename" :language="de" 
                                class="form-control"
                                placeholder="Seminardatum"
                                ></datepicker>
                    <!-- <input type="text" v-model="datum"> -->
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Begin:
                </div>
                <div class="col">
                    <time-picker format="HH:mm" placeholder="Startzeit" v-model="begin" size="large"></time-picker>
                    {{begin}}
                    <!-- <input type="text" v-model="begin"> -->
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Ende:
                </div>
                <div class="col">
                    <time-picker format="HH:mm" placeholder="Endzeit" v-model="ende" size="large"></time-picker>
                    <!-- <input type="text" v-model="ende" class="form-control"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale'

import { TimePicker } from 'ant-design-vue';
export default {
    name: 'ModalSeminar',
    components: {
        Datepicker,
        TimePicker
    },
    data: () => ({
        datum: '',
        begin: '',
        ende: '',
        thema: '',
        ort: '',
        de: de,
        orte: [],
        themen: [],
        seminar: {}
    }),
    props: {
        seminarid: Number 
    },
    mounted() {
        this.getOrte()
        this.getThemen()
        if(this.seminarid) this.getSeminar()
    },
    methods: {
        getOrte() {
            axios.get('/login.php?editor=seminarverwaltung&rest=orte')
                .then(response => {
                    //  console.log(response.data)
                    this.orte = response.data
                })
        },
        getThemen() {
            axios.get('/login.php?editor=seminarverwaltung&rest=themen')
                .then(response => {
                    //  console.log(response.data)
                    this.themen = response.data
                })
        },
        getSeminar() {
            axios.get('/login.php?editor=seminarverwaltung&rest=seminare&id='+ this.seminarid)
                .then(response => {
                    //  console.log(response.data)
                    this.seminar = response.data
                })
        }
    }
}
</script>

<style lang="scss">
.seminar {
    .seminar-item {
        display: flex;
        margin-bottom: 5px;
        .seminar-col {
            flex-basis: 50%;
            .vdp-datepicker {
                input {
                    border: 0px;
                }
            }
            input {
                width: 100%
            }
        }
    }
}
</style>
