import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter, faCity, faBuilding, faRoad} from '@fortawesome/free-solid-svg-icons'
import { faCalendarPlus, faAngry, faEnvelope, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons'
// import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add( faCalendarPlus, faAngry, faEnvelope, faFilter, faTrashAlt, faCity, faBuilding, faRoad, faEdit)
Vue.component('font-awesome-icon', FontAwesomeIcon)


import SlideUpDown from 'vue-slide-up-down'
 
Vue.component('slide-up-down', SlideUpDown)

// import VueFilterDateFormat from 'vue-filter-date-format'
// Vue.use(VueFilterDateFormat)

// import 'bootstrap/dist/css/bootstrap.min.css'
// Required
// import 'roboto-npm-webfont';
import './scss/seminare/style.scss'
import 'ant-design-vue/dist/antd.css'; 

// import VueNumberInput from '@chenfengyuan/vue-number-input'; 
// Vue.component(VueNumberInput.name, VueNumberInput);

import vmodal from 'vue-js-modal'
Vue.use(vmodal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true
})

// import vueScrollupMenu from 'vue-scrollup-menu'
// Vue.directive('vue-scrollup-menu', vueScrollupMenu)

// import vSelect from 'vue-select'
// Vue.component('v-select', vSelect)


// import { Carousel, Slide } from 'vue-carousel';

// k-112.de Rechner
  import SeminarAdministration from './components/seminarverwaltung/SeminarAdministration.vue'
  // import FamWhiteBox from './components/fam/FamWhiteBox.vue'
  // import FamSlider from './components/fam/FamSlider.vue'
  // import ImageModal from './components/fam/ImageModal.vue'
  // import ImageModal from './components/fam/ImageModal.vue'
  // import FamHeader from './components/fam/FamHeader.vue'


new Vue({
  el: '#main',
  components: {
    SeminarAdministration
  }
});

// new Vue({
//   el: '#footer',
//   components: {
//   }
// });