<template>
<div>
	<modals-container></modals-container>
    <h2>Dashboard</h2>
    <div class="dashboard">
        <div class="seminare">
            <div class="header" @mouseenter="filter = true" @mouseleave="filter = false">
                <div class="header-inside">
                    <h3>Aktuelle Seminare</h3>
                    <div class="header-tools">
                        <!-- <font-awesome-icon  :icon="['fas', 'filter']" size="lg"
                                            @click="filter = !filter"
                                            ></font-awesome-icon> -->
                        <font-awesome-icon :icon="['far', 'calendar-plus']" size="lg" @click="openSeminarModal"></font-awesome-icon>
                    </div>
                </div>
                <slide-up-down :active="filter" :duration="400" class="filter">
                        Orte:
                    <div class="buttons">
                        <button @click="ortefilter = ''" class="btn btn-outline-secondary"
                                :class="{active: ortefilter === ''}"
                                >Alle</button>
                        <button @click="ortefilter = 'Dortmund'" class="btn btn-outline-secondary"
                                :class="{active: ortefilter === 'Dortmund'}">Dortmund</button>
                        <button @click="ortefilter = 'Hamburg'" class="btn btn-outline-secondary"
                                :class="{active: ortefilter === 'Hamburg'}">Hamburg</button>
                    </div>
                    <div class="text-input">
                        <input  type="text" v-model="namefilter" 
                                class="form-control"
                                placeholder="Suche"
                                >
                    </div>
                </slide-up-down>
            </div>
            <div class="inside">
                <w-seminar-list :ortefilter="ortefilter"
                                :namefilter="namefilter"></w-seminar-list>
            </div>
        </div>
        <div class="einstellungen">
            <div class="header">
                <h3>Einstellungen</h3>
            </div>
            <div class="inside">
                <div class="item pointer" @click="openOrteModal">
                    Orte Bearbeiten
                </div>
                <div class="item pointer" @click="openThemenModal">
                    Seminarthemen anzeigen
                </div>
                <div class="item pointer" @click="openThemaModal">
                    Seminarthema erstellen
                </div>
            </div>
        </div>
    </div>
    
</div>
</template>

<script>
import WSeminarList from './WSeminarList.vue'
import ModalOrte from './ModalOrte.vue'
import ModalThemen from './ModalThemen.vue'
import ModalThema from './ModalThema.vue'
import ModalSeminar from './ModalSeminar.vue'
export default {
    data: () => ({
        seminare: [],
        filter: false,
        ortefilter: '',
        namefilter: '',
    }),
    components: {
        WSeminarList
    },
    methods: {
        openOrteModal() {
            this.$modal.show(ModalOrte, {}, {
                height: 'auto',
                width: '900px',
                adaptive: true
            })
        },
        openThemenModal() {
            this.$modal.show(ModalThemen, {}, {
                height: 'auto',
                width: '800px',
                adaptive: true
            })
        },
        openThemaModal() {
            this.$modal.show(ModalThema, {}, {
                height: 'auto',
                width: '800px',
                adaptive: true
            })
        },
        openSeminarModal() {
            this.$modal.show(ModalSeminar, {}, {
                height: '500px',
                width: '800px',
                adaptive: true
            })
        },
    }
}
</script>

<style lang="scss">
.dashboard {
    display: flex;
    align-items: flex-start;
    .seminare, .einstellungen {
        border: 1px solid black;
        border-radius: 1px 1px;
        margin-left: 10px;
        margin-right: 10px;
        .header {
            border-bottom: 1px solid black;
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .seminare {
        flex-basis: 80%;
            .buttons {
                margin-bottom: 5px;
                .btn {
                    margin-right: 5px;
                }
            }
            .text-input {
                width: 50%;
                margin-bottom: 5px;
            }
        .header-inside {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .filter {
            // flex-basis: 100%;
        }
    }
    .einstellungen {
        flex-basis: 20%;
        .item {
            padding: 10px;
            &:hover {
                background-color: gray;
            }
        }
    }
}
</style>
