<template>
    <div class="seminar" 
            @mouseover="entered = true"  @mouseout="entered = false"
            @click="openSeminarModal"
            :class="{entered: entered}"
            >
        <div>
            <b>{{date}}</b>
            <small>({{starttime}} - {{endtime}})</small>
            {{seminar.stadt}} |
            {{seminar.thema}}
        </div>
        <div>
            Anmeldungen: {{seminar.anmeldungen}}
        </div>
    </div>
</template>

<script>
import ModalSeminar from './ModalSeminar.vue'
export default {
    data: () => ({
        entered: false
    }),
    props: {
        seminar: Array
    },
    methods: {
        openSeminarModal() {
            this.$modal.show(ModalSeminar, {
                seminarid: this.seminar.id
            })
        }
    },
    computed: {
        date() {
            let date = new Date(this.seminar.start*1000)
            return date.getDate() + '.' + (date.getMonth()+1) + '.' + date.getFullYear()
        },
        starttime() {
            let date = new Date(this.seminar.start*1000)
            return date.getHours() + ':' + (date.getMinutes() < 10 ? 0 : '') + date.getMinutes()
        },
        endtime() {
            let date = new Date(this.seminar.end*1000)
            return date.getHours() + ':' + (date.getMinutes() < 10 ? 0 : '') + date.getMinutes()
        },
    }
}
</script>

<style lang="scss">
.seminar {
    padding:10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.entered {
        background-color: gray;
    }
}
</style>
