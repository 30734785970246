<template>
    <div> {{ortefilter}}
        <w-seminar  v-for="seminar in filteredList" :key="seminar.id" 
                    :seminar="seminar"
                    >
        </w-seminar>
    </div>
</template>

<script>
import axios from 'axios'
import WSeminar from './WSeminar.vue'
export default {
    data: () => ({
        seminare: [ ],
    }),
    mounted() {
        this.getSeminare()
    },
    props: {    
        ortefilter: String,
        namefilter: {
            type: String,
            default: ''
        }
    },
    components: {
        WSeminar
    },
    methods: {
        getSeminare() {
            axios.get('/login.php?editor=seminarverwaltung&rest=seminare')
                .then(response => {
                    //  console.log(response.data)
                    this.seminare = response.data
                })
        }
    },
    computed: {
        filteredList() {
            let myseminars = this.seminare;
            if (this.ortefilter !== '') myseminars = myseminars.filter( seminar => seminar.stadt === this.ortefilter) 

            if (this.namefilter !== '') myseminars = myseminars.filter( seminar => seminar.thema.toLowerCase().includes(this.namefilter.toLowerCase())) 
            return myseminars
        },
    }
}
</script>

<style>

</style>
