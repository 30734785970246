<template>
    <div>
        <div class="row hover" @mouseenter="hovered = true" @mouseleave="hovered = false">
            <div class="col-6">
                {{thema.name}}
            </div>
            <div class="col-2">
                {{thema.isSeminar === '1' ? 'Seminar': 'AGT'}}
            </div>
            <div class="col-2">
                {{thema.date}}
            </div>
            <div class="col-2">
                <font-awesome-icon  :icon="['far', 'trash-alt']" size="lg" class="pointer"
                                        @click="delThema(thema.id)"
                                        v-if="hovered"
                                    ></font-awesome-icon>
                <font-awesome-icon  :icon="['far', 'edit']" size="lg" class="pointer"
                                        @click="editThema"
                                        v-if="hovered"
                                    ></font-awesome-icon>
            </div>
        </div>
        <slide-up-down :active="edit" :duration="400">
            <div  class="edit-themen">

                <div class="form-group">
                    <input type="text" v-model="thema.name" placeholder="Name der Adresse" class="form-control">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Beschreibung:</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" v-model="thema.description"></textarea>
                </div>
                <button @click="updateThema" class="btn btn-outline-primary" 
                        >
                            Update
                </button>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
import axios from 'axios'
import ModalThema from './ModalThema.vue'
export default {
    data: () => ({
        hovered: false,
        edit: false
    }),
    props: {
        thema: Array
    },
    methods: {
        delThema(id) {
            console.log('delete'+ id)
            if (id) {
                axios.delete('/login.php?editor=seminarverwaltung&rest=themen&id='+ id)
                .then(response => {
                    console.log(response)
                    // this.newstadt = this.newvort = this.newadresse = ''
                    this.$emit('delThema')
                    // this.orte = response.data
                })
            }
        },
        editThema() {
              this.$modal.show(ModalThema, {}, {
                height: '500px',
                width: '800px',
                adaptive: true
            })
        },
        updateThema(id) {
            console.log('delete'+ id)
            if (id) {
                axios.delete('/login.php?editor=seminarverwaltung&rest=themen&id='+ id)
                .then(response => {
                    console.log(response)
                    // this.newstadt = this.newvort = this.newadresse = ''
                    this.$emit('delThema')
                    // this.orte = response.data
                })
            }
        },
    }
}
</script>

<style>
.edit-themen {
    border: 1px solid #ced4da;;
    border-radius: 4px;
    margin: 5px 20px;
    padding: 5px;
}
</style>
