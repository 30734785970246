<template>
            <div class="list-item hover" @mouseenter="hovered = true" @mouseleave="hovered = false">
                <div class="s-col">

                    <font-awesome-icon  :icon="['fas', 'city']" size="md"></font-awesome-icon>
                    {{ort.stadt}}
                </div>
                <div class="s-col">
                    <font-awesome-icon  :icon="['fas', 'building']" size="md"></font-awesome-icon>
                    {{ort.ort}}
                </div>
                <div class="s-col">
                    <font-awesome-icon  :icon="['fas', 'road']" size="md"></font-awesome-icon>
                    {{ort.strasse}}
                </div>
                <div class="s-col-auto">
                    <font-awesome-icon  :icon="['far', 'trash-alt']" size="lg" class="pointer"
                                         @click="delOrt(ort.id)"
                                         v-if="hovered"
                                        ></font-awesome-icon>
                </div>
            </div>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        hovered: false
    }),
    props: {
        ort: Array
    },
    methods: {
        delOrt(id) {
            console.log('delete'+ id)
            if (id) {
                axios.delete('/login.php?editor=seminarverwaltung&rest=orte&id='+ id)
                .then(response => {
                    console.log(response)
                    // this.newstadt = this.newvort = this.newadresse = ''
                    this.$emit('delOrt')
                    // this.orte = response.data
                })
            }
        },
    }
}
</script>

<style>

</style>
